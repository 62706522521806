import React, { useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Radio,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  RadioGroup,
  Stack,
  TextField,
  Divider
} from '@mui/material'

import {
  Close as CloseIcon
} from '@mui/icons-material'

import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import { defaultFunction, getCompanies } from '../../utilities/utilities'
import { api } from '../../../api/api'
import { defaultCatch, defaultSuccess, setSnackbar, setLoading } from '../../../state/actions'
import Field from '../Field/Field'
import VisitAutoComplete from '../VisitAutoComplete/VisitAutoComplete'
import { DEFAULT_AUTOCOMPLETE } from '../../../variables'

function EditDialog(props) {
  const {
    open = false,
    companies = [],
    enabled_visit_photo = false,
    fetchVisits = defaultFunction,
    defaultCatch,
    defaultSuccess,
    setLoading,
    item = {}
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()


  const [identification, setIdentification] = useState(item.identification)
  const [first_name, setFirstName] = useState('')
  const [last_name_one, setLastNameOne] = useState('')
  const [last_name_two, setLastNameTwo] = useState('')
  const [comes_from, setComesFrom] = useState('')
  const [company, setCompany] = useState(DEFAULT_AUTOCOMPLETE)
  const [hasCardNumber, setHasCardNumber] = useState(false)
  const [has_laptop, setHasLaptop] = useState(false)
  const [card_number, setCardNumber] = useState('')
  const [laptop_serie, setLaptopSerie] = useState('')
  const [notes, setNotes] = useState('')
  const [photo, setPhoto] = useState('')


  const onClose = useCallback(() => {
    setPhoto('')
    if (props.onClose) props.onClose()
  }, [props])

  const setVisit = useCallback(visit => {
    const {
      identification = '',
      first_name = '',
      last_name_one = '',
      last_name_two = '',
      company_id = -1,
      comes_from = '',
      laptop_serie = '',
      has_laptop = false,
      card_number = '',
      notes = ''
    } = visit

    setIdentification(identification)
    setFirstName(first_name)
    setLastNameOne(last_name_one)
    setLastNameTwo(last_name_two)
    setComesFrom(comes_from)
    setNotes(notes)

    if (company_id && company_id !== -1 && companies.length) {
      setCompany(companies.find(c => c.id === company_id))
    }

    if (has_laptop && laptop_serie) {
      setHasLaptop(true)
      setLaptopSerie(laptop_serie)
    } else {
      setHasLaptop(false)
      setLaptopSerie('')
    }

    if (card_number) {
      setHasCardNumber(true)
      setCardNumber(card_number)
    } else {
      setHasCardNumber(false)
      setCardNumber('')
    }


  }, [companies])

  useEffect(() => {
    setVisit(item)

    const { photo = {} } = item
    if (photo.id) {
      api.get(`visit/photo/${photo.id}`, { responseType: "blob" })
        .then(defaultSuccess)
        .then(({ data }) => {
          if (data.size) setPhoto(URL.createObjectURL(data))
        })
        .catch(defaultCatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  const onUpdateVisit = useCallback(async () => {

    const payload = {
      visit: {
        identification,
        first_name,
        last_name_one,
        last_name_two,
        company_id: company.id,
        comes_from,
        card_number,
        notes,
        has_laptop
      }
    }

    try {
      const response = await api.put(`/visit/${item.id}`, payload)
      defaultSuccess(response)
      const { data } = response
      if (!data.success) {
        setSnackbar({
          open: true,
          message: data.errors[0],
          severity: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'right',
        })
      }
      else return data.visit
    } catch (error) {
      defaultCatch(error, navigate)
    }
  }, [identification, first_name, last_name_one, last_name_two, company, comes_from, card_number, notes, has_laptop, item, defaultSuccess, defaultCatch, navigate])


  const onUpdate = useCallback(async () => {
    setLoading(true)
    await onUpdateVisit()
    setLoading(false)
    setSnackbar({
      open: true,
      message: t('Successfully registered visitor'),
      severity: 'success',
      autoHideDuration: 2000,
      vertical: 'top',
      horizontal: 'right',
    })

    onClose()
    fetchVisits()
  }, [fetchVisits, onClose, onUpdateVisit, setLoading, t])

  const onChangeCardNumber = value => {
    setHasCardNumber(value === 'true')
    setCardNumber('')
  }

  const onChangePortail = value => {
    setHasLaptop(value === 'true')
    setLaptopSerie('')
  }

  const onChangeCompany = (_, company) => {
    if (!company) setCompany(DEFAULT_AUTOCOMPLETE)
    else setCompany(company)
  }

  const isEnableSave = useCallback(() => {
    return (
      first_name &&
      last_name_one &&
      company.id !== -1 &&
      comes_from &&
      (!hasCardNumber || card_number) &&
      (!has_laptop || laptop_serie)
    )
  }, [card_number, comes_from, company, first_name, hasCardNumber, has_laptop, laptop_serie, last_name_one])

  return (
    <Dialog
      className='EditDialog'
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogContent className="dialog-info-content">
        <Stack gap={4} alignItems="left">
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <Stack direction="row" alignItems="center" gap={1}>
              <IconButton
                aria-label="close"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
              <label>
                Edita el registro de visitante
              </label>
            </Stack>
          </Stack>
          <Field
            direction="column"
            alignItems="left"
            label={t('Identification')}
            value={identification}
            onChange={e => setIdentification(e.target.value)}
          />
          <Stack direction="column" gap={2}>
            <label>Escribe los datos del visitante</label>
            <Stack direction="row" gap={2}>
              <Field
                sx={{ width: '100%' }}
                placeholder={t('name')}
                value={first_name}
                onChange={e => setFirstName(e.target.value)}
              />
              <Field
                sx={{ width: '100%' }}
                placeholder={`${t('last name')} 1`}
                value={last_name_one}
                onChange={e => setLastNameOne(e.target.value)}
              />
              <Field
                sx={{ width: '100%' }}
                placeholder={`${t('last name')} 2`}
                value={last_name_two}
                onChange={e => setLastNameTwo(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack direction="row" gap={2}>
            <VisitAutoComplete
              sx={{ width: '100%' }}
              direction="column"
              alignItems="left"
              label={t('comes from')}
              value={comes_from}
              setValue={setComesFrom}
            />
            <Stack
              sx={{ width: '100%' }}
              className='Field'
              gap={2}
              direction="column"
              alignItems="left"
              justifyContent="space-between"
            >
              <label>{t('go to')}</label>
              <Autocomplete
                size='small'
                fullWidth
                options={getCompanies(companies)}
                getOptionLabel={option => option.name}
                onChange={onChangeCompany}
                value={company}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label={t('write')}
                    variant="filled"
                  />}
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            gap={2}
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
          >
            <Stack
              className='Field'
              gap={2}
              direction="column"
              alignItems="left"
              sx={{ width: '100%' }}
            >
              <label>{t('Card')}</label>
              <Stack
                gap={2}
                direction="column"
              >
                <FormControl>
                  <RadioGroup
                    row
                    name="card"
                    value={hasCardNumber}
                    onChange={(_, value) => onChangeCardNumber(value)}
                  >
                    <FormControlLabel value={true} control={<Radio />} label={t('Yes')} />
                    <FormControlLabel value={false} control={<Radio />} label={t('No')} />
                  </RadioGroup>
                </FormControl>
                {hasCardNumber &&
                  <Field
                    fullWidth
                    label={t('Card number')}
                    value={card_number}
                    onChange={e => setCardNumber(e.target.value)}
                  />
                }
              </Stack>
            </Stack>
            <Stack
              className='Field'
              gap={2}
              direction="column"
              alignItems="left"
              sx={{ width: '100%' }}
            >
              <label>Portátil</label>
              <Stack
                gap={2}
                direction="column"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="laptop-radio"
                    name="laptop"
                    value={has_laptop}
                    onChange={(_, value) => onChangePortail(value)}
                  >
                    <FormControlLabel value={true} control={<Radio />} label={t('Yes')} />
                    <FormControlLabel value={false} control={<Radio />} label={t('No')} />
                  </RadioGroup>
                </FormControl>
                {has_laptop &&
                  <Field
                    fullWidth
                    label={t('No. Serie')}
                    value={laptop_serie}
                    onChange={e => setLaptopSerie(e.target.value)}
                  />
                }
              </Stack>
            </Stack>
          </Stack>

          {enabled_visit_photo && 
            photo &&
            <Stack
              className='Field'
              gap={2}
              direction="column"
              alignItems="left"
            >
              <label>{t('Fotografía (Opcional)')}</label>
              <div className='photo-prev-wrapper'>
                <img src={photo} alt="user" />
              </div>
                
            </Stack>
          }
          <Field
            direction='column'
            alignItems='left'
            minRows={4}
            multiline
            label={t('Notas (Opcional)')}
            value={notes}
            placeholder="Si lo deseas puedes escribir una nota."
            onChange={e => setNotes(e.target.value)}
          />

          <Stack
            alignItems="end"
          >
            <Button
              sx={{ width: 316 }}
              disabled={!isEnableSave()}
              variant="contained"
              size="large"
              onClick={onUpdate}
            >
              {t('confirm')}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = state => {
  return {
    companies: state.profile.companies,
    enabled_visit_photo: state.profile.enabled_visit_photo,
    enabled_visit_qr: state.profile.enabled_visit_qr
  }
}

const mapDispatchToProps = {
  setSnackbar,
  setLoading,
  defaultSuccess,
  defaultCatch
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDialog)
